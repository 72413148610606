var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "d-flex", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-start align-center",
              attrs: { cols: "5" },
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "padding-left": "136px",
                    "padding-top": "333px",
                    "border-radius": "0px",
                  },
                  attrs: {
                    flat: "",
                    height: "805px",
                    width: "771px",
                    color: "#FAFAFA",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex justify-start align-center",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c("div", [
                        _c("h1", { staticClass: "heading1" }, [
                          _vm._v(" IELTS: Your Bridge to"),
                          _c("br"),
                          _vm._v(" Global Fluency "),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "pt-12",
                          staticStyle: {
                            "font-family": "Roboto",
                            "font-size": "18px",
                            "font-style": "normal",
                            "font-weight": "400",
                            "line-height": "140%",
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " Unleash Your Potential, Conquer the Test, You Success - Our Priority "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              staticStyle: {
                                background: "#0099dc",
                                "margin-top": "64px",
                              },
                              attrs: { to: { path: "/auth/register" } },
                            },
                            [
                              _vm._v(" Start a Free Trial "),
                              _c(
                                "v-icon",
                                { staticClass: "pl-2", attrs: { size: "20" } },
                                [_vm._v("mdi-arrow-right")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "padding-top": "90px",
                    "padding-left": "50px",
                    "z-index": "4",
                  },
                  attrs: {
                    height: "794",
                    color: "transparent",
                    width: "865",
                    flat: "",
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/ielts/banner1.webp") },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "center", align: "center" } },
        [
          _c("v-card", {
            staticStyle: {
              "z-index": "2",
              "margin-left": "-225px",
              "border-radius": "0px",
            },
            attrs: { flat: "", width: "153", height: "151", color: "#FAFAFA" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        {
          attrs: {
            id: "ielts-features",
            "no-gutters": "",
            justify: "center",
            align: "center",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "", width: "1920", height: "330" } },
            [
              _c(
                "v-img",
                {
                  attrs: {
                    contain: "",
                    "max-height": "330",
                    width: "1920",
                    height: "330",
                    src: require("@/assets/ielts/section2/background-sec2.png"),
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        justify: "start",
                        align: "center",
                        "align-content": "center",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-center align-center d-flex",
                          staticStyle: {
                            "flex-direction": "column",
                            height: "330px",
                            "margin-left": "136px",
                          },
                          attrs: { cols: "2" },
                        },
                        [
                          _c(
                            "h1",
                            {
                              staticClass: "white--text pb-8",
                              staticStyle: {
                                "font-family": "Roboto",
                                "font-size": "42px",
                                "font-style": "normal",
                                "font-weight": "600",
                                "line-height": "normal",
                                "letter-spacing": "0.672px",
                                "text-transform": "capitalize",
                              },
                            },
                            [_vm._v(" Learn, Practice, Excel With Us ")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "white--text",
                              staticStyle: {
                                "font-family": "Nunito Sans",
                                "font-size": "20px",
                                "font-style": "normal",
                                "font-weight": "400",
                                "line-height": "170%",
                                "/* 34px */\n                letter-spacing":
                                  "0.32px",
                              },
                            },
                            [
                              _vm._v(
                                " Begin your IELTS journey with our expert guidance "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "justify-center align-center d-flex",
                          attrs: { cols: "8" },
                        },
                        [
                          _c(
                            "v-slide-group",
                            {
                              attrs: {
                                "mobile-breakpoint": "450",
                                "center-active": "",
                              },
                            },
                            _vm._l(_vm.slideItemIcons, function (iconPath, i) {
                              return _c(
                                "v-slide-item",
                                {
                                  key: i,
                                  style:
                                    i == 1
                                      ? `margin-left: 40px;`
                                      : `margin-left: 82px;`,
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "white--text",
                                      attrs: {
                                        flat: "",
                                        color: "transparent",
                                        width: "220",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require(`@/assets/ielts/section2/${
                                            i + 1
                                          }icon.svg`),
                                          alt: "image icon",
                                        },
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "pt-5 pb-3",
                                          staticStyle: {
                                            "font-family": "Roboto",
                                            "font-size": "22px",
                                            "font-style": "normal",
                                            "font-weight": "400",
                                            "line-height": "normal",
                                            "letter-spacing": "0.352px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.slideItemHeaders[i]) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "font-family": "Roboto",
                                            "font-size": "16px",
                                            "font-style": "normal",
                                            "font-weight": "400",
                                            "line-height": "153%",
                                            "/* 24.48px */\n                      letter-spacing":
                                              "0.256px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.slideItemDescriptions[i]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticStyle: { margin: "100px" },
          attrs: {
            id: "ielts-getting-started",
            "no-gutters": "",
            justify: "space-between",
          },
        },
        [
          _c("v-col", { staticClass: "d-flex justify-center align-center" }, [
            _c("img", {
              attrs: {
                width: "595",
                height: "728",
                src: require("@/assets/ielts/section3/exam.webp"),
              },
            }),
          ]),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-start align-top",
              staticStyle: { "margin-right": "232px" },
            },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "h1",
                    {
                      staticStyle: {
                        "font-family": "Roboto",
                        "font-size": "54px",
                        "font-style": "normal",
                        "font-weight": "700",
                        "line-height": "110%",
                      },
                    },
                    [_vm._v(" Achieve a Higher IELTS score ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "pt-9",
                      staticStyle: {
                        "/* Body/L */\n            font-family": "Roboto",
                        "font-size": "18px",
                        "font-style": "normal",
                        "font-weight": "400",
                        "line-height": "140%",
                      },
                    },
                    [
                      _vm._v(
                        " Our Comprehensive Course Structure Will take you to your end goal of getting a perfect score on all four sections. "
                      ),
                    ]
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "d-flex justify-center mt-10",
                      staticStyle: { "align-items": "center" },
                      attrs: {
                        tile: "",
                        width: "210",
                        height: "50",
                        flat: "",
                        color: "#CFF0FF",
                      },
                    },
                    [_vm._v(" What's Included ")]
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column align-start",
                          attrs: { cols: "12" },
                        },
                        _vm._l(_vm.features, function (feature, index) {
                          return _c(
                            "v-card",
                            {
                              key: index,
                              staticClass:
                                "d-flex justify-start align-center mt-8",
                              attrs: { flat: "" },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-check-all")]),
                              _c("span", { staticClass: "pl-4" }, [
                                _vm._v(_vm._s(feature)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: { "padding-top": "56px" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "#0099dc",
                            to: { path: "/auth/register" },
                          },
                        },
                        [_vm._v(" Sign up ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text ml-4",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/ielts/courseDetails")
                            },
                          },
                        },
                        [_vm._v(" View Full course ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticStyle: { "margin-bottom": "91px" },
          attrs: { "no-gutters": "", align: "center", justify: "center" },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { flat: "" },
            },
            [
              _c(
                "v-img",
                { attrs: { src: require("@/assets/ielts/background2.svg") } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex align-center fill-height px-15",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column align-start",
                          attrs: { cols: "8" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                color: "#FFF",
                                "margin-bottom": "9px",
                                "font-family": "Roboto",
                                "font-size": "24px",
                                "font-style": "normal",
                                "font-weight": "700",
                                "line-height": "normal",
                                "text-transform": "uppercase",
                              },
                            },
                            [_vm._v(" Subscribe for updates! ")]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                color: "#FFF",
                                "font-family": "Roboto",
                                "font-size": "14px",
                                "font-style": "normal",
                                "font-weight": "400",
                                "line-height": "normal",
                              },
                            },
                            [
                              _vm._v(
                                " Stay informed with our latest updates by subscribing today! "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "4" },
                        },
                        [
                          _c("input", {
                            staticClass: "email-input",
                            attrs: {
                              id: "email-input",
                              type: "text",
                              placeholder: "Enter your email",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-4 rounded-l-0",
                              staticStyle: {
                                "margin-left": "-144px",
                                height: "40px",
                                "margin-top": "0",
                              },
                            },
                            [_vm._v("Subscribe Now ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-row no-gutters class="d-flex">
      <v-col class="d-flex justify-start align-center" cols="5">
        <v-card
          flat
          height="805px"
          width="771px"
          color="#FAFAFA"
          style="padding-left: 136px; padding-top: 333px; border-radius: 0px"
        >
          <v-row no-gutters class="d-flex justify-start align-center">
            <div>
              <h1 class="heading1">
                IELTS: Your Bridge to<br />
                Global Fluency
              </h1>
            </div>
            <div
              class="pt-12"
              style="
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
              "
            >
              <p>
                Unleash Your Potential, Conquer the Test, You Success - Our
                Priority
              </p>
            </div>
            <div>
              <v-btn
                style="background: #0099dc; margin-top: 64px"
                class="white--text"
                :to="{ path: '/auth/register' }"
              >
                Start a Free Trial
                <v-icon size="20" class="pl-2">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          height="794"
          color="transparent"
          width="865"
          flat
          style="padding-top: 90px; padding-left: 50px; z-index: 4"
        >
          <img :src="require('@/assets/ielts/banner1.webp')" />
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" align="center">
      <v-card
        style="z-index: 2; margin-left: -225px; border-radius: 0px"
        flat
        width="153"
        height="151"
        color="#FAFAFA"
      >
      </v-card>
    </v-row>
    <v-row id="ielts-features" no-gutters justify="center" align="center">
      <v-card flat width="1920" height="330">
        <v-img
          contain
          max-height="330"
          width="1920"
          height="330"
          :src="require('@/assets/ielts/section2/background-sec2.png')"
        >
          <v-row
            no-gutters
            justify="start"
            align="center"
            align-content="center"
          >
            <v-col
              class="justify-center align-center d-flex"
              cols="2"
              style="flex-direction: column; height: 330px; margin-left: 136px"
            >
              <!-- <v-card color="transparent"> -->
              <h1
                class="white--text pb-8"
                style="
                  font-family: Roboto;
                  font-size: 42px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.672px;
                  text-transform: capitalize;
                "
              >
                Learn, Practice, Excel With Us
              </h1>
              <p
                class="white--text"
                style="
                  font-family: Nunito Sans;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 170%; /* 34px */
                  letter-spacing: 0.32px;
                "
              >
                Begin your IELTS journey with our expert guidance
              </p>
              <!-- </v-card> -->
            </v-col>
            <v-col cols="8" class="justify-center align-center d-flex">
              <v-slide-group mobile-breakpoint="450" center-active>
                <v-slide-item
                  v-for="(iconPath, i) in slideItemIcons"
                  :key="i"
                  :style="i == 1 ? `margin-left: 40px;` : `margin-left: 82px;`"
                >
                  <v-card
                    flat
                    color="transparent"
                    class="white--text"
                    width="220"
                  >
                    <img
                      :src="require(`@/assets/ielts/section2/${i + 1}icon.svg`)"
                      alt="image icon"
                    />
                    <p
                      class="pt-5 pb-3"
                      style="
                        font-family: Roboto;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.352px;
                      "
                    >
                      {{ slideItemHeaders[i] }}
                    </p>
                    <p
                      style="
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 153%; /* 24.48px */
                        letter-spacing: 0.256px;
                      "
                    >
                      {{ slideItemDescriptions[i] }}
                    </p>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
    </v-row>
    <v-row
      id="ielts-getting-started"
      style="margin: 100px"
      no-gutters
      justify="space-between"
    >
      <v-col class="d-flex justify-center align-center">
        <img
          width="595"
          height="728"
          :src="require('@/assets/ielts/section3/exam.webp')"
        />
      </v-col>
      <v-col class="d-flex justify-start align-top" style="margin-right: 232px">
        <v-card flat>
          <h1
            style="
              font-family: Roboto;
              font-size: 54px;
              font-style: normal;
              font-weight: 700;
              line-height: 110%; /* 59.4px */
            "
          >
            Achieve a Higher IELTS score
          </h1>
          <p
            class="pt-9"
            style="
              /* Body/L */
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 25.2px */
            "
          >
            Our Comprehensive Course Structure Will take you to your end goal of
            getting a perfect score on all four sections.
          </p>
          <v-card
            class="d-flex justify-center mt-10"
            tile
            style="align-items: center"
            width="210"
            height="50"
            flat
            color="#CFF0FF"
          >
            What's Included
          </v-card>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" class="d-flex flex-column align-start">
              <v-card
                v-for="(feature, index) in features"
                :key="index"
                class="d-flex justify-start align-center mt-8"
                flat
              >
                <v-icon>mdi-check-all</v-icon>
                <span class="pl-4">{{ feature }}</span>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters style="padding-top: 56px">
            <v-btn
              class="white--text"
              color="#0099dc"
              :to="{ path: '/auth/register' }"
            >
              Sign up
            </v-btn>
            <v-btn
              class="primary--text ml-4"
              outlined
              @click="$router.push('/ielts/courseDetails')"
            >
              View Full course
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      align="center"
      justify="center"
      style="margin-bottom: 91px;"
    >
      <v-card class="d-flex align-center justify-center" flat>
        <v-img :src="require('@/assets/ielts/background2.svg')">
          <v-row no-gutters class="d-flex align-center fill-height px-15">
            <v-col cols="8" class="d-flex flex-column align-start">
              <p
                style="
                color:  #FFF;
                margin-bottom: 9px;
                font-family: Roboto;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;"
              >
                Subscribe for updates!
              </p>
              <p
                style="
              color:  #FFF;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;"
              >
                Stay informed with our latest updates by subscribing today!
              </p>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <input
                id="email-input"
                type="text"
                placeholder="Enter your email"
                class="email-input"
              />
              <v-btn
                class="pa-4 rounded-l-0"
                style="margin-left: -144px; height: 40px; margin-top: 0;"
                >Subscribe Now
              </v-btn>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "IeltsHome",

  data() {
    return {
      slideItemIcons: [
        "@/assets/ielts/section2/1icon.svg",
        "@/assets/ielts/section2/2icon.svg",
        "@/assets/ielts/section2/3icon.svg",
        "@/assets/ielts/section2/4icon.svg",
        "@/assets/ielts/section2/5icon.svg",
        "@/assets/ielts/section2/6icon.svg"
      ],
      slideItemHeaders: [
        "Lectures, notes",
        "Pro Tips & Tricks",
        "Learn & Practice",
        "Mock Tests",
        "Speaking & Writing Assessment",
        "One On One Mentorship "
      ],
      slideItemDescriptions: [
        "Expert instructors & comprehensive study materials.",
        "Expert strategies to ace your exam with confidence",
        "Enhance skills through interactive lessons and hands-on exercises",
        "Improve your score with realistic mock tests",
        "Enhance speaking and writting with assessments",
        "Personalized guidance for achieving success"
      ],
      features: [
        "Comprehensive lectures for each module: Listening, Reading, Writing & Speaking",
        "Step by step instructions for solving all Listening and Reading question types",
        "Expert guideline on writing a report for Academic Writing Task 1",
        "Practical structure for writing varied letters for General Training Task 1",
        "Elaborate instruction on crafting insightful essays for Academic Writing Task 2",
        "Practice questions aligned with lectures",
        "Curated lists of vocabulary for all proficiency levels",
        "50+ question specific mock test",
        "Pro tips and tricks for each question type"
      ]
    };
  }
};
</script>

<style lang="scss">
.heading1 {
  color: #2b4570;
  font-family: Roboto;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.growth-card {
  width: 364px;
  height: 230px;
  border-radius: 16px !important;
  box-shadow: 3px 4px 14px 0px rgba(255, 255, 255, 0.5) inset;
}

.growth-card-title {
  color: #323232;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.growth-card-content {
  color: #323232;
  font-family: Roboto;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.growth-card-subTitle {
  color: #5e4a4a;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-input::placeholder {
  color: white;
  opacity: 0.5;
}

.email-input {
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  width: 300px;
  color: white;
  padding-left: 16px;
}

.email-input:focus {
  color: white;
  border: 1px solid white;
}

#email-input:focus {
  outline: 1px solid white;
}
</style>

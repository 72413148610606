// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".heading1 {\n  color: #2b4570;\n  font-family: Roboto;\n  font-size: 54px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 110%;\n}\n.growth-card {\n  width: 364px;\n  height: 230px;\n  border-radius: 16px !important;\n  box-shadow: 3px 4px 14px 0px rgba(255, 255, 255, 0.5) inset;\n}\n.growth-card-title {\n  color: #323232;\n  font-family: Roboto;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n.growth-card-content {\n  color: #323232;\n  font-family: Roboto;\n  font-size: 52px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n.growth-card-subTitle {\n  color: #5e4a4a;\n  font-family: Roboto;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.email-input::-moz-placeholder {\n  color: white;\n  opacity: 0.5;\n}\n.email-input::placeholder {\n  color: white;\n  opacity: 0.5;\n}\n.email-input {\n  border: 1px solid white;\n  border-radius: 5px;\n  height: 40px;\n  width: 300px;\n  color: white;\n  padding-left: 16px;\n}\n.email-input:focus {\n  color: white;\n  border: 1px solid white;\n}\n#email-input:focus {\n  outline: 1px solid white;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
